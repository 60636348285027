import _objectSpread from "/Users/mac/\u745E\u548C\u5EB7/FrisClient2/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.array.concat.js";
import frisServer from '../utils/frisServer';
import dasServer from '../utils/dasServer';
export function loginRequest(params) {
  return frisServer({
    url: '/sys/login',
    method: 'post',
    data: _objectSpread({}, params)
  });
}
export function dasLoginRequest(params) {
  return dasServer({
    url: "/sys/login?email=".concat(params.email, "&phone=").concat(params.phone, "&password=").concat(params.password, "&rememberMe=true"),
    method: 'post',
    data: _objectSpread({}, params),
    headers: {
      'content-type': 'application/x-www-form-urlencoded'
    }
  });
}
export function logout() {
  return frisServer({
    url: "/sys/logout",
    method: 'post',
    data: {}
  });
}
export function sendResetPasswordCode(data) {
  return frisServer({
    url: '/sys/sendResetPasswordCode',
    method: 'post',
    data: data
  });
}
export function resetPassword(data) {
  return frisServer({
    url: '/sys/resetPassword',
    method: 'post',
    data: data
  });
} // 驾驶舱数据关联

export function dasSelectUserPlaces(token) {
  return dasServer({
    url: '/place/selectUserPlaces',
    method: 'get',
    params: {
      token: token
    },
    headers: {
      'content-type': 'application/json;charset=UTF-8'
    }
  });
}