import "core-js/modules/es.object.to-string.js";
import axios from 'axios';
import router from '@/router';
import storage from 'store';
import { dasBaseUrl } from '@/config';
var dasServer = axios.create({
  baseURL: dasBaseUrl,
  timeout: 1000 * 30,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json; charset=utf-8'
  }
});
/**
 * 请求拦截
 */

dasServer.interceptors.request.use(function (config) {
  if (config.headers) {
    config.headers['source'] = 'fris';
  }

  var token = storage.get('dasToken');

  if (token && config.headers) {
    config.headers['logintoken'] = token; // 请求头带上token
  }

  return config;
}, function (error) {
  return Promise.reject(error);
});
dasServer.interceptors.response.use(function (response) {
  if (response.data.code === undefined) {
    return response;
  }

  if (response.data.code !== 0) {
    if (response.data.code === 401) {
      // 401, token失效
      router.push('/login');
    }

    return Promise.reject(response);
  }

  return response;
}, function (error) {
  return Promise.reject(error);
});
export default dasServer;