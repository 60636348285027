import { resolveComponent as _resolveComponent, toHandlers as _toHandlers, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_date_picker = _resolveComponent("el-date-picker");

  var _component_fr_form_item = _resolveComponent("fr-form-item");

  var _component_form_item = _resolveComponent("form-item");

  return _openBlock(), _createBlock(_component_form_item, {
    props: _ctx.props
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_fr_form_item, {
        mode: _ctx.props.mode,
        content: _ctx.value
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_el_date_picker, _mergeProps({
            modelValue: _ctx.modelValue,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
              return _ctx.modelValue = $event;
            }),
            editable: false,
            type: "date",
            style: {
              "width": "100%"
            },
            "value-format": "YYYY-MM-DD 00:00:00",
            "disabled-date": _ctx.disabledDate
          }, _ctx.props.datePickerProps, {
            placeholder: _ctx.computedPlaceholder
          }, _toHandlers(_ctx.formDatePickerEvents)), null, 16, ["modelValue", "disabled-date", "placeholder"])];
        }),
        _: 1
      }, 8, ["mode", "content"])];
    }),
    _: 1
  }, 8, ["props"]);
}