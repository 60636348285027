import _slicedToArray from "/Users/mac/\u745E\u548C\u5EB7/FrisClient2/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import _objectSpread from "/Users/mac/\u745E\u548C\u5EB7/FrisClient2/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import { ref, computed, onMounted, provide, watch } from "vue";
import { useStore } from "vuex";
import GlobalSidebar from "@/components/GlobalSidebar.vue";
import { useRouter, useRoute } from 'vue-router'; // import enLocale from 'element-plus/lib/locale/lang/en'

import zhCn from 'element-plus/lib/locale/lang/zh-cn';
import { batchDispatchOrganizationActions, batchDispatchAppActions, timeOutLogin } from '@/utils/util';
import PutOnRecords from '@/components/PutOnRecords.vue'; // session_timeout.png

export default {
  components: {
    GlobalSidebar: GlobalSidebar,
    // [ElConfigProvider.name]: ElConfigProvider,
    PutOnRecords: PutOnRecords
  },
  setup: function setup() {
    var store = useStore();
    var collapse = computed(function () {
      return store.state.app.collapse;
    });
    var appContentRef = ref(null);
    var router = useRouter();
    var route = useRoute();
    var locale = ref(zhCn);
    onMounted(function () {
      if (store.state.user.token) {
        batchDispatchOrganizationActions(store);
        batchDispatchAppActions(store);

        if (!store.state.message.isConnect) {
          store.dispatch('message/connectMQ');
        }
      }
    });

    var scrollTo = function scrollTo(poisition) {
      appContentRef.value && appContentRef.value.scrollTo(_objectSpread(_objectSpread({}, poisition), {}, {
        behavior: 'smooth'
      }));
    };

    provide('scrollTo', scrollTo);
    provide('frFormItemType', 'form'); // const locale = ref(zhLocale)
    // const changeLocale = (type: 'zh-cn' | 'en') => {
    //   locale.value = type === 'zh-cn' ? zhLocale : enLocale
    // }
    // provide('changeLocale', changeLocale)

    onMounted(function () {
      showMessage.value = !!store.state.message.message;
    });
    watch([function () {
      return store.state.message.message;
    }, function () {
      return store.state.user.token;
    }, function () {
      return store.state.message.count;
    }], function (_ref) {
      var _ref2 = _slicedToArray(_ref, 3),
          message = _ref2[0],
          token = _ref2[1],
          messageCount = _ref2[2];

      if (message && token && messageCount && messageCount > 0) {
        showMessage.value = true;
      } else {
        showMessage.value = false;
      }
    });
    var isShowSideBar = computed(function () {
      return !route.meta.hideSlider && store.getters['app/getCurrentOrganizationId'];
    });
    var userId = computed(function () {
      return store.state.user.userId;
    });
    var count = computed(function () {
      return store.state.message.count;
    });

    var messageClickHandle = function messageClickHandle() {
      if (count.value === 1 && store.state.message.message) {
        router.push("/email/news-info/".concat(store.state.message.message.id));
      } else {
        router.push("/email/my-news");
      }

      showMessage.value = false;
    };

    var showMessage = ref(false);
    var timefilter = ref();
    var timefilterTime = ref(0);
    var timeOutLoginFlag = ref(true);
    var sessionTimeoutVisible = computed(function () {
      var keepLogin = store.state.user.keepLogin;

      if (!keepLogin) {
        return store.state.app.sessionTimeout;
      } else {
        return false;
      }
    });
    var permissionsVisible = computed(function () {
      return store.state.user.isShowNoPermissions;
    });

    var timefilterHadnle = function timefilterHadnle() {
      timefilter.value = setTimeout(function () {
        timefilterTime.value += 1;

        if (timefilterTime.value === 300) {
          timeOutLoginFlag.value = true;
        } else {
          timefilterHadnle();
        }
      }, 1000);
    };

    var resetTimeoutHndle = function resetTimeoutHndle() {
      if (!timeOutLoginFlag.value) return;
      var isLoginPage = window.location.href.indexOf('/login'); //  登录页

      var isUserResetPage = window.location.href.indexOf('/user-reset'); //  忘记密码页

      if (isLoginPage !== -1 || isUserResetPage !== -1) return;
      timefilterTime.value = 0;
      window.clearTimeout(timefilter.value);
      timefilterHadnle();
      timeOutLogin(3600000, 1000);
      timeOutLoginFlag.value = false;
    };

    var loginOutHandle = function loginOutHandle() {
      store.commit('app/changeSessionTimeout', false);
      store.dispatch('user/logout');
    };

    var permissionsCloseHandle = function permissionsCloseHandle() {
      store.commit('user/updateIsShowNoPermissions', false);
    };

    return {
      isShowSideBar: isShowSideBar,
      userId: userId,
      count: count,
      showMessage: showMessage,
      collapse: collapse,
      appContentRef: appContentRef,
      messageClickHandle: messageClickHandle,
      locale: locale,
      router: router,
      resetTimeoutHndle: resetTimeoutHndle,
      sessionTimeoutVisible: sessionTimeoutVisible,
      permissionsVisible: permissionsVisible,
      loginOutHandle: loginOutHandle,
      permissionsCloseHandle: permissionsCloseHandle
    };
  }
};