import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_fr_selector = _resolveComponent("fr-selector");

  var _component_fr_form_item = _resolveComponent("fr-form-item");

  var _component_form_item = _resolveComponent("form-item");

  return _openBlock(), _createBlock(_component_form_item, {
    props: _ctx.props
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_fr_form_item, {
        mode: _ctx.props.mode,
        content: _ctx.labelValue
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_fr_selector, {
            active: _ctx.modelActive,
            props: _ctx.selectorProps
          }, null, 8, ["active", "props"])];
        }),
        _: 1
      }, 8, ["mode", "content"])];
    }),
    _: 1
  }, 8, ["props"]);
}