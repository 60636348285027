import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, toHandlers as _toHandlers, mergeProps as _mergeProps, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  class: "empty",
  style: {
    "color": "#999"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_option = _resolveComponent("el-option");

  var _component_el_select = _resolveComponent("el-select");

  return _openBlock(), _createBlock(_component_el_select, _mergeProps({
    key: _ctx.props.list.length,
    ref: "selectRef",
    multiple: _ctx.multiple,
    filterable: "",
    remote: "",
    "model-value": _ctx.modelActive,
    "remote-method": _ctx.handleQuerySearchAsync,
    placeholder: _ctx.computedPlaceholder,
    loading: _ctx.loading,
    "suffix-transition": false,
    "no-match-text": "",
    "no-data-text": ""
  }, _ctx.props.selectProps, _toHandlers(_ctx.selectEvents), {
    onChange: _ctx.handleChange
  }), {
    empty: _withCtx(function () {
      return [_createElementVNode("div", {
        class: "item-row flex flex-row justify-between items-center empty-box",
        onClick: _cache[0] || (_cache[0] = _withModifiers(function () {
          return _ctx.handlePromptClick && _ctx.handlePromptClick.apply(_ctx, arguments);
        }, ["stop"]))
      }, [_createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.emptyTextName), 1), _createElementVNode("div", {
        class: "add-item",
        style: _normalizeStyle("color: ".concat(_ctx.emptyAddBtnColor))
      }, _toDisplayString(_ctx.emptyAddBtnName), 5)])];
    }),
    default: _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx._list, function (item) {
        return _openBlock(), _createBlock(_component_el_option, {
          key: item && item[_ctx.props.list.value],
          label: item && item[_ctx.props.list.label],
          value: _ctx.isReturnObject ? item && item : item && item[_ctx.props.list.value],
          disabled: _ctx.disabledSelectHandle(item && item[_ctx.props.list[_ctx.disabledKeyNmae]])
        }, null, 8, ["label", "value", "disabled"]);
      }), 128))];
    }),
    _: 1
  }, 16, ["multiple", "model-value", "remote-method", "placeholder", "loading", "onChange"]);
}